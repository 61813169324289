import React, { useState } from "react";
import logo from "./img/timetab128.png";
import logo_poing from "./img/logo_white.png";
import img_webstore from "./img/webstore.png";
import "./App.css";
import { Switch, Route, useLocation, useParams, Link } from "react-router-dom";

import EnglishFlag from "./img/flags/US.svg";
import SpanishFlag from "./img/flags/ES.svg";
import GermanFlag from "./img/flags/DE.svg";

import { useTranslation, Trans } from "react-i18next";

import { Select, MenuItem, ListItemIcon } from "@material-ui/core";

import _ from "lodash";

import img_timetabmockup from "./img/mocktimetab.png";

import img_sunhours from "./img/screenshots/sunhours.png";
import img_comingmonths from "./img/screenshots/comingmonths.png";
import img_lifecalendar from "./img/screenshots/lifecalendar.png";
import img_minblocks from "./img/screenshots/minblocks.png";
import img_nextholiday from "./img/screenshots/nextholiday.png";
import img_settings from "./img/screenshots/settings.png";
import img_yearprogress from "./img/screenshots/yearprogress.png";
import img_timers from "./img/screenshots/timers.png";

/*import GitHubIcon from '@material-ui/icons/GitHub';*/
/*import MailOutlineIcon from '@material-ui/icons/MailOutline';*/
/*import InstagramIcon from '@material-ui/icons/Instagram';*/

function Header(props) {
  const { i18n } = useTranslation();
  const location = useLocation();
  const section = location.pathname.split("/")[1];
  const [selectedLang, setSelectedLang] = useState(i18n.language || "en");

  const handleLanguageChange = (lang) => {
    setSelectedLang(lang);
    props.setSelectedLang(lang);
    i18n.changeLanguage(lang);
  };

  return (
    <header>
      <div className="header-container">
        <Link to="/"><div className="logo-container">
          <img className="logo" src={logo} alt="Logo" /> timetab
        </div></Link>
        <div>
        <nav>
        <ul>
          <li>
            <Link to="/demo">Demo</Link>
          </li>
          <li>
          <Select
            variant="outlined"
            style={{ height: "40px", marginRight: "20px" }}
            value={selectedLang}
            defaultValue={selectedLang}
            onChange={(event) => handleLanguageChange(event.target.value)}
          >
            <MenuItem key="EN" value="en">
              <ListItemIcon style={{ minWidth: "30px" }}>
                <img style={{ height: "20px" }} src={EnglishFlag} alt="EN" />
              </ListItemIcon>
              EN
            </MenuItem>
            <MenuItem key="ES" value="es">
              <ListItemIcon style={{ minWidth: "30px" }}>
                <img style={{ height: "20px" }} src={SpanishFlag} alt="ES" />
              </ListItemIcon>
              ES
            </MenuItem>
            <MenuItem key="DE" value="de">
              <ListItemIcon style={{ minWidth: "30px" }}>
                <img style={{ height: "20px" }} src={GermanFlag} alt="DE" />
              </ListItemIcon>
              DE
            </MenuItem>
          </Select>
          </li>
          </ul></nav>
          
        </div>
      </div>
    </header>
  );
}

function Footer() {
  const location = useLocation();
  const section = location.pathname.split("/")[1];

  return (
    <footer>
      <div className="footer-container">
        <div>
          Developed by <img src={logo_poing} alt="Poing Labs" /> Labs
        </div>
        </div>
    </footer>
  );
}

function Entry(props) {
  const data = props.data;

  const is_even = (props.index + 1) % 2 === 0;

  return (
    <div className={is_even ? "entry entry_even" : "entry entry_uneven"}>
      <div className="entry_image">
        <img src={data.image} alt={data.title} />
      </div>
      <div
        className={is_even ? "entry_container entry_even" : "entry_container entry_uneven"}
      >
        <h2 className="entry__title">{data.title}</h2>
        <p className="entry__description">{data.text}</p>
      </div>
    </div>
  );
}

function List(props) {
  const entries = props.entries;
  let filteredEntries = entries;
  if (props.category !== "home") {
    filteredEntries =
      _.filter(entries, function (o) {
        return o.category === props.category;
      }) || [];
  }
  return (
    <div>
      <div className="entries-separator"></div>
      <div className="entries" style={props.style}>
        {filteredEntries.map((item, index) => {
          return <Entry key={item.id} index={index} data={item} />;
        })}
      </div>
    </div>
  );
}

function Page(props) {
  const { c, p } = useParams();
  let filteredEntry =
    _.filter(props.entries, function (o) {
      return o.category === c && o.id === p;
    }) || [];
  const entry = filteredEntry[0];
  const EntryComponent = entry["component"];

  return (
    <div className="page" style={props.style}>
      <h2 className="page__title">{entry["name"]}</h2>
      <EntryComponent />
    </div>
  );
}

function Home(props) {
  return (
    <div className="page_home" style={props.style}>
      <div className="header-hero">
        <div className="hero-container-left">
          <h1 id="hero-title">
            <Trans i18nKey="heroTitle">
              Sync your browser with
              <br />
              your life and nature
            </Trans>
          </h1>
          <div className="hero-subtitle">
            <Trans i18nKey="heroSubTitle">
              Timetab is a Chrome extension that adds high-definition dynamic
              backgrounds, different weather visualizations, and other useful
              features to your browser's new tab.
            </Trans>
          </div>
          <div className="hero-buttons">
            <a
              href="https://chrome.google.com/webstore/detail/timetab/liobdfgkcgpgfeoemlcoelichpjpbkhe?hl=es"
              rel="noreferrer"
              className="link-add-to-browser"
            >
              <button className="gradient-button">
                + <Trans i18nKey="heroButton">Agregar al navegador</Trans>
              </button>
            </a>{" "}
            <a
              href="https://chrome.google.com/webstore/detail/timetab/liobdfgkcgpgfeoemlcoelichpjpbkhe?hl=es"
              rel="noreferrer"
              className="link-add-to-browser"
            >
              <img
                style={{ height: "60px" }}
                src={img_webstore}
                alt="Available in Chrome web store"
              />
            </a>
          </div>
        </div>
        <div className="hero-container-right">
          <img src={img_timetabmockup} alt="timetab mockup" />
        </div>
      </div>
      <List category="home" entries={props.entries} />
      <div className="hero-buttons" style={{margin: "0px auto 40px", justifyContent: "center"}}>
            <a
              href="https://chrome.google.com/webstore/detail/timetab/liobdfgkcgpgfeoemlcoelichpjpbkhe?hl=es"
              rel="noreferrer"
              className="link-add-to-browser"
            >
              <button className="gradient-button">
                + <Trans i18nKey="heroButton">Agregar al navegador</Trans>
              </button>
            </a>{" "}
            <a
              href="https://chrome.google.com/webstore/detail/timetab/liobdfgkcgpgfeoemlcoelichpjpbkhe?hl=es"
              rel="noreferrer"
              className="link-add-to-browser"
            >
              <img
                style={{ height: "60px" }}
                src={img_webstore}
                alt="Available in Chrome web store"
              />
            </a>
          </div>
    </div>
  );
}

function Demo(props) {
  return <div className="page" style={props.style}>
    <h2 className="page__title">Demo video</h2>
    <div className="demo"><div className="videowrapper"><iframe src="https://www.youtube.com/embed/0TVkv5OIGEU" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe></div></div>
    <div className="hero-buttons" style={{margin: "0px auto 40px", justifyContent: "center"}}>
            <a
              href="https://chrome.google.com/webstore/detail/timetab/liobdfgkcgpgfeoemlcoelichpjpbkhe?hl=es"
              rel="noreferrer"
              className="link-add-to-browser"
            >
              <button className="gradient-button">
                + <Trans i18nKey="heroButton">Agregar al navegador</Trans>
              </button>
            </a>{" "}
            <a
              href="https://chrome.google.com/webstore/detail/timetab/liobdfgkcgpgfeoemlcoelichpjpbkhe?hl=es"
              rel="noreferrer"
              className="link-add-to-browser"
            >
              <img
                style={{ height: "60px" }}
                src={img_webstore}
                alt="Available in Chrome web store"
              />
            </a>
          </div>
    
    </div>;
}

function PrivacyPolicy(props) {
  return <div className="page" style={props.style}>
    <h2 className="page__title">Privacy Policy</h2>
    <p>Thank you for using TimeTab! This Privacy Policy outlines how TimeTab collects, uses, and protects any information that you provide when using the Chrome extension.</p>

    <h2>Information We Collect</h2>
    <p>TimeTab does not collect any personal data or information from its users. We do not gather, store, or share any personally identifiable information.</p>

    <h2>How We Use Information</h2>
    <p>Since we do not collect any information, there is no information to use. TimeTab is designed to respect your privacy and operates solely within the confines of your web browser without transmitting any data externally.</p>

    <h2>Information Security</h2>
    <p>We take your privacy and security seriously. Even though we do not collect any information, we continuously monitor our systems to ensure that your browsing experience remains safe and secure.</p>

    <h2>Changes to This Privacy Policy</h2>
    <p>TimeTab may update this Privacy Policy from time to time. Any changes will be reflected on this page. We encourage you to review this Privacy Policy periodically for any updates.</p>

    <p>Thank you for using TimeTab! We hope you enjoy a seamless and private browsing experience with our extension.</p>
    
    </div>;
}

function App() {
  const location = useLocation();
  const { i18n } = useTranslation();
  const [selectedLang, setSelectedLang] = useState(i18n.language || "en");

  const entries = [
    {
      id: "sunhours",
      title: i18n.t("features.sunhours_title"),
      text: i18n.t("features.sunhours_text"),
      image: img_sunhours,
    },
    {
      id: "timers",
      title: i18n.t("features.timers_title"),
      text: i18n.t("features.timers_text"),
      image: img_timers,
    },
    {
      id: "comingmonths",
      title: i18n.t("features.comingmonths_title"),
      text: i18n.t("features.comingmonths_text"),
      image: img_comingmonths,
    },
    {
      id: "minblocks",
      title: i18n.t("features.minblocks_title"),
      text: i18n.t("features.minblocks_text"),
      image: img_minblocks,
    },
    {
      id: "yearprogress",
      title: i18n.t("features.yearprogress_title"),
      text: i18n.t("features.yearprogress_text"),
      image: img_yearprogress,
    },
    {
      id: "nextholiday",
      title: i18n.t("features.nextholiday_title"),
      text: i18n.t("features.nextholiday_text"),
      image: img_nextholiday,
    },
    {
      id: "lifecalendar",
      title: i18n.t("features.lifecalendar_title"),
      text: i18n.t("features.lifecalendar_text"),
      image: img_lifecalendar,
    },
    {
      id: "settings",
      title: i18n.t("features.settings_title"),
      text: i18n.t("features.settings_text"),
      image: img_settings,
    }
  ];

  return (
    <div className="App">
      <Header setSelectedLang={setSelectedLang}/>
      <div className="routes">
        <Switch location={location}>
          <Route exact path="/">
            <Home entries={entries} />
          </Route>
          <Route exact path="/demo">
            <Demo />
          </Route>
          <Route exact path="/privacy-policy">
            <PrivacyPolicy />
          </Route>
        </Switch>
      </div>
      <Footer />
    </div>
  );
}

export default App;
