import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

//import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
// don't want to use this?
// have a look at the Quick start guide 
// for passing in lng and translations on init

const resources = {
  en: {
    translation: {
      "heroTitle": "Sync your browser with<1 />your life and nature",
      "heroSubTitle" : "Timetab is a Chrome extension that adds high-definition dynamic backgrounds, different time visualizations, and other useful features to your browser's new tab.",
      "heroButton" : "Add to browser",
      "features" : {
        "sunhours_title" : "Sunrise, Sunset & Moonphase",
        "sunhours_text" : "Don't miss the sunset, sunrise, or your favorite moon phase. Enjoy dynamic backgrounds that adapt to your selected theme and time of day.",
        "comingmonths_title" : "Upcoming months",
        "comingmonths_text" : "Plan your trips and vacations with a full view of the upcoming months. Customize the number of months you want to see and add custom holidays or vacation days.",
        "minblocks_title" : "Your day in 10 minutes blocks",
        "minblocks_text" : "How many blocks are left in the day to stay on track with your daily habits?",
        "yearprogress_title" : "Year progress",
        "yearprogress_text" : "Time flies, and the year passes quickly. Stay in sync with your mid-term goals.",
        "nextholiday_title" : "Upcoming holiday",
        "nextholiday_text" : "Stay informed about upcoming holidays and plan your events accordingly",
        "lifecalendar_title" : "Your life calendar",
        "lifecalendar_text" : "Create your own life calendar, review it from time to time, and motivate yourself to start pursuing your life goals today.",
        "settings_title" : "Custom settings",
        "settings_text" : "Change the language or theme of your timetab, import your country's holidays, or collaborate with a donation.",
        "timers_title" : "Quick timers",
        "timers_text" : "Click on the current time and drag outside to set up a quick timer",

      }
    },
  },
  es: {
    translation: {
      "heroTitle": "Sincroniza tu navegador con<1 /> tu vida y la naturaleza",
      "heroSubTitle" : "Timetab es una extensión de Chrome que añade fondos dinamicos en alta definición, diferentes visualizaciones del tiempo y otras características útiles a la nueva pestaña de tu navegador.",
      "heroButton" : "Agregar al navegador",
      "features" : {
        "sunhours_title" : "Amanecer, Atardecer y Fases de la Luna",
        "sunhours_text" : "No te pierdas el atardecer, amanecer o tu fase favorita de la luna. Disfruta de fondos dinámicos que se adaptan a tu tema seleccionado y hora del día.",
        "comingmonths_title" : "Próximos meses",
        "comingmonths_text" : "Planifica tus viajes y vacaciones con una vista completa de los próximos meses. Personaliza el número de meses que deseas ver y agrega días de vacaciones o feriados personalizados.",
        "minblocks_title" : "Tu día en bloques de 10 minutos",
        "minblocks_text" : "¿Cuántos bloques quedan en el día para mantenerte en el camino de tus hábitos diarios?",
        "yearprogress_title" : "Progreso del año",
        "yearprogress_text" : "El tiempo vuela y el año pasa rápidamente. Mantente en sincronía con tus metas a medio plazo.",
        "nextholiday_title" : "Próximo feriado",
        "nextholiday_text" : "Mantente informado sobre los próximos feriados y planifica tus eventos en consecuencia",
        "lifecalendar_title" : "Tu calendario de vida",
        "lifecalendar_text" : "Crea tu propio calendario de vida, revísalo de vez en cuando y motívate para comenzar a perseguir tus metas hoy mismo.",
        "settings_title" : "Configuraciones personalizadas",
        "settings_text" : "Cambia el idioma o el tema de tu timetab, importa los feriados de tu país o colabora con una donación.",
        "timers_title": "Temporizadores rápidos",
        "timers_text": "Haz clic en la hora actual y arrástrala hacia afuera para configurar un temporizador rápido",
      }
    },
  },
  de: {
    translation: {
      "heroTitle": "Sync deinen Browser mit<1 /> deinem Leben und der Natur.",
      "heroSubTitle" : "Timetab ist eine Chrome-Extension, die hochauflösende dynamische Hintergründe, verschiedene Zeitvisualisierungen und andere nützliche Funktionen zur neuen New Tab Ihres Browsers hinzufügt.",
      "heroButton" : "Zum Browser hinzufügen",
      "features" : {
        "sunhours_title" : "Sonnenaufgang, Sonnenuntergang und Mondphase",
        "sunhours_text" : "Verpassen Sie nicht den Sonnenuntergang, Sonnenaufgang oder Ihre Lieblings-Mondphase. Genießen Sie dynamische Hintergründe, die sich an Ihr ausgewähltes Thema und die Tageszeit anpassen.",
        "comingmonths_title" : "Kommende Monate",
        "comingmonths_text" : "Planen Sie Ihre Reisen und Urlaube mit einem vollständigen Blick auf die kommenden Monate. Passen Sie die Anzahl der Monate an, die Sie sehen möchten, und fügen Sie benutzerdefinierte Feiertage oder Urlaubstage hinzu.",
        "minblocks_title" : "Ihr Tag in 10-Minuten-Blöcken",
        "minblocks_text" : "Wie viele Blöcke sind noch übrig, um mit Ihren täglichen Gewohnheiten Schritt zu halten?",
        "yearprogress_title" : "Jahresfortschritt",
        "yearprogress_text" : "Die Zeit vergeht schnell und das Jahr geht schnell vorbei. Bleiben Sie im Einklang mit Ihren mittelfristigen Zielen.",
        "nextholiday_title" : "Nächster Feiertag",
        "nextholiday_text" : "Bleiben Sie über bevorstehende Feiertage informiert und planen Sie Ihre Veranstaltungen entsprechend",
        "lifecalendar_title" : "Ihr Lebenskalender",
        "lifecalendar_text" : "Erstellen Sie Ihren eigenen Lebenskalender, überprüfen Sie ihn von Zeit zu Zeit und motivieren Sie sich selbst, um Ihre Lebensziele heute zu verfolgen.",
        "settings_title" : "Benutzerdefinierte Einstellungen",
        "settings_text" : "Ändern Sie die Sprache oder das Thema Ihres timetab, importieren Sie die Feiertage Ihres Landes oder arbeiten Sie mit einer Spende zusammen.",
        "timers_title": "Schnelle Timer",
        "timers_text": "Klicken Sie auf die aktuelle Uhrzeit und ziehen Sie sie nach draußen, um einen schnellen Timer einzurichten",
      }
    },
  },
};

i18n
  // load translation using http -> see /public/locales (i.e. https://github.com/i18next/react-i18next/tree/master/example/react/public/locales)
  // learn more: https://github.com/i18next/i18next-http-backend
  //.use(Backend)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    resources,
    fallbackLng: 'en',
    //load: 'all',
    debug: false,
    supportedLngs: ['en', 'de', 'es'],
    nonExplicitSupportedLngs: false,
    detection: {
      order: ['querystring', 'navigator'],
      lookupQuerystring: 'lng',
      checkWhitelist: true
    },
    react: { 
        useSuspense: false //   <---- this will do the magic
    },
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    }
  });

export default i18n;